import { graphql, useStaticQuery } from "gatsby"

import Blog from "./blog";
import React from 'react';

const BlogPost = () => {
    const blogQueryData = useStaticQuery(graphql`
        query BlogListQuery {
            allMarkdownRemark (limit: 3, sort: {order: DESC, fields: frontmatter___date}) {
                edges {
                    node {
                        fields {
                            slug
                        }
                        excerpt(pruneLength: 83, format: PLAIN, truncate: true)
                        frontmatter {
                            author {
                                name
                            }
                            title
                            date(formatString: "MMM Do, YYYY")
                            format
                            category
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 374, maxHeight: 280, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                        presentationWidth
                                        presentationHeight
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }
        }
    `);


    const blogs = blogQueryData.allMarkdownRemark.edges;
    return (
        <div className="rn-post-area rn-section-gapBottom pt--200 bg-color-grey" id="news">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title mb--40">
                            <h2 className="title">CIJENE NAJMA <span className="bg">2024</span></h2>
                        </div>
                    </div>
                </div>
                
                {/* <div className="row row--30">
                    {blogs.map(blog => (
                        <div className="col-lg-4 col-md-6 col-12 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="0.8s" key={blog.node.fields.slug}>
                            <Blog
                                image={blog.node.frontmatter.image.childImageSharp.fluid}
                                title={blog.node.frontmatter.title}
                                path={blog.node.fields.slug}
                            />
                        </div>
                    ))}
                </div> */}

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                <table className="table table-hover">
                                                <thead style={{background: "#3aaae1", color: "white", fontWeight: "bolder"}}>
                                                    <tr>
                                                    <th scope="col" style={{background: "#3aaae1", color: "white", fontWeight: "bolder"}}>Naziv broda</th>
                                                    <th scope="col" style={{background: "#3aaae1", color: "white", fontWeight: "bolder"}}>01.06. - 06.07.<br/>
                                                Dan / Tjedan (EUR)</th>
                                                    <th scope="col" style={{background: "#3aaae1", color: "white", fontWeight: "bolder"}}>07.07. - 03.09.<br/>
                                                Dan / Tjedan (EUR)</th>
                                                    <th scope="col" style={{background: "#3aaae1", color: "white", fontWeight: "bolder"}}>04.09. - 30.09.<br/>
                                                Dan / Tjedan (EUR)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                    <th scope="row">Ranieri Stargate - Suzuki 200</th>
                                                    <td>200 / 1260	</td>
                                                    <td>280 / 1400	</td>
                                                    <td>200 / 1260  </td>
                                                    </tr>
                                                    <tr>
                                                    <th scope="row">Joker boat Coaster 480 - Yamaha 60</th>
                                                    <td>120 / 700	</td>
                                                    <td>150 / 840	</td>
                                                    <td>120 / 700   </td>
                                                    </tr>
                                                    <tr>
                                                    <th scope="row">Marvel 19 - Suzuki 100</th>
                                                    <td>150 / 875	</td>
                                                    <td>200 / 1100	</td>
                                                    <td>150 / 875   </td>
                                                    </tr>
                                                    <tr>
                                                    <th scope="row">Ranieri Pathfinder - Yamaha 115</th>
                                                    <td>150 / 875	</td>
                                                    <td>200 / 1100	</td>
                                                    <td>150 / 875   </td>
                                                    </tr>
                                                    <tr>
                                                    <th scope="row">Nuova Jolly King 490 - Yamaha 100</th>
                                                    <td>150 / 875   </td>
                                                    <td>200 / 1100	</td>
                                                    <td>200 / 1100   </td>
                                                    </tr>
                                                    <tr>
                                                    <th scope="row">Roto 450 - Yamaha 8</th>
                                                    <td>100 	</td>
                                                    <td>100 	</td>
                                                    <td>100  </td>
                                                    </tr>
                                                    <tr>
                                                    <th scope="row">Beneteau Flyer 550 - Yamaha 150</th>
                                                    <td>180 / 1100	</td>
                                                    <td>240 / 1330	</td>
                                                    <td>180 / 1100</td>
                                                    </tr>
                                                    <tr>
                                                    <th scope="row">Saver 19 - Yamaha 115</th>
                                                    <td>150 / 875	</td>
                                                    <td>200 / 1100	</td>
                                                    <td>150 / 875  </td>
                                                    </tr>
                                                    <tr>
                                                    <th scope="row">Roto 450 - Yamaha 8</th>
                                                    <td>100 	</td>
                                                    <td>100 	</td>
                                                    <td>100  </td>
                                                    </tr>
                                                    <tr>
                                                    <th scope="row">Ranieri Voyager 22 - Suzuki 200</th>
                                                    <td>200 / 1260	</td>
                                                    <td>280 / 1400	</td>
                                                    <td>200 / 1260</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                </div>
                            </div>
                        </div>
            </div>
        </div>
    )
}

export default BlogPost;
