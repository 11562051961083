import {FiMessageSquare, FiPhoneCall} from "react-icons/fi"

import {HiOutlineMailOpen} from "react-icons/hi"
import Image from "../image";
import Img from 'gatsby-image'
import {Link} from 'gatsby'
import React from 'react';
import bi1 from "../../data/images/boaticons/1.png"
import bi2 from "../../data/images/boaticons/2.png"
import bi3 from "../../data/images/boaticons/3.png"
import bi4 from "../../data/images/boaticons/4.png"
import bi5 from "../../data/images/boaticons/5.png"
import bi6 from "../../data/images/boaticons/6.png"
import bi7 from "../../data/images/boaticons/7.png"
import bi8 from "../../data/images/boaticons/8.png"
import bi9 from "../../data/images/boaticons/9.png"

const Projectcard = ({image, id, title, category, column, bl, bw, pax, ce, speed, engine, kg, fuel, water, pricefrom, season1, season2, season3}) => {
    let projectImg;
    if(image.fixed && typeof image.fixed !== 'function'){
        projectImg = <Img fixed={image.fixed} alt={title}/>;
    } else if(image.fluid){
        projectImg = <Image fluid={image.fluid} alt={title}/>
    } else{
        projectImg = <img src={image.src} alt={title}/>
    }
    return (
        <div className={column}>
            <div className="portfolio">
                <div className="thumbnail">
                    <Link to={`/rent-a-boat/${id}`}>
                        {projectImg}
                    </Link>
                </div>
                <div className="content">
                    <div className="inner">
                        {title && 
<>
                        <div className="row mb--10">
                        
                            <div className="col-lg-12 col-md-12 col-xs-12">

                                <h4 className="title" style={{textAlign: "left", marginBottom: "15px"}}><Link to={`/rent-a-boat/${id}`}>{title}</Link></h4>
                                <hr style={{width:"98.5%", margin: "0 auto", height:"1px", background:"lightgray", marginBottom: "15px"}} ></hr> 
                                <h6 className="subtitle" style={{textAlign: "left"}}><strong>{season1}</strong> po danu</h6>
                                <h6 className="subtitle" style={{textAlign: "left"}}><strong>{season2}</strong> po danu</h6>
                                <h6 className="subtitle" style={{textAlign: "left"}}><strong>{season3}</strong> po danu</h6>
                                <hr style={{width:"98.5%", margin: "0 auto", height:"1px", background:"lightgray"}} ></hr> 
                            </div>
                        </div>

                        <div className="row mb--10">

                            <div className="col-lg-3 col-md-3 col-6" style={{textAlign: "center"}}>

                                <div className="thumbnail-inner">
                                    <img src={bi1} alt="icon boat length" width={48} /><br /><small>{bl}</small>
                                </div>

                            </div>
                            <div className="col-lg-3 col-md-3 col-6" style={{textAlign: "center"}}>

                                <div className="thumbnail-inner">
                                    <img src={bi2} alt="icon boat width" width={48}/><br /><small>{bw}</small>
                                </div>

                            </div>

                            <div className="col-lg-3 col-md-3 col-6" style={{textAlign: "center"}}>

                                    <div className="thumbnail-inner">
                                        <img src={bi3} alt="icon boat max persons" width={48}/><br /><small>{pax}</small>
                                    </div>

                            </div>

                            <div className="col-lg-3 col-md-3 col-6" style={{textAlign: "center"}}>

                                <div className="thumbnail-inner">
                                    <img src={bi4} alt="icon boat weight" width={48}/><br /><small>{kg}</small>
                                </div>

                            </div>
                            <div className="col-lg-3 col-md-3 col-6" style={{textAlign: "center"}}>

                                <div className="thumbnail-inner">
                                    <img src={bi5} alt="icon boat fuel in liters" width={48}/><br /><small>{fuel}
                                    </small>
                                </div>

                            </div>

                            <div className="col-lg-3 col-md-3 col-6" style={{textAlign: "center"}}>

                                    <div className="thumbnail-inner">
                                        <img src={bi6} alt="icon boat water in liters" width={48}/><br /><small>{water}</small>
                                    </div>

                            </div>

                            <div className="col-lg-3 col-md-3 col-6" style={{textAlign: "center"}}>

                                <div className="thumbnail-inner">
                                    <img src={bi7} alt="icon boat category" width={48}/><br /><small>{ce}</small>
                                </div>

                            </div>
                            <div className="col-lg-3 col-md-3 col-6" style={{textAlign: "center"}}>

                                <div className="thumbnail-inner">
                                    <img src={bi8} alt="icon boat speed" width={48}/><br /><small>{speed}</small>
                                </div>

                            </div>

                            <div className="col-lg-12 col-md-12 col-12" style={{textAlign: "center"}}>

                                    <div className="thumbnail-inner">
                                        <img src={bi9} alt="icon boat engine" width={48}/><br /><small>{engine}</small>
                                    </div>

                            </div>

                            <div className="col-lg-12 col-md-12 col-12" style={{height: "10px"}}>
                            <hr style={{width:"98.5%", margin: "0 auto", height:"1px", background:"lightgray"}}></hr> 
                            </div>
                            
                        </div>
                        
  </>                      
                        }
                        {category && 
                        
                        <div className="row mb--10">
                            <div className="col-lg-6 col-md-6 col-12 mt--10">
                                <div style={{textAlign: "center", background: "#3aaae1", padding: "5px", border: "1px solid gray", borderRadius: "8px"}}>
                                    <span className="category"><a href="tel:+385915805907" style={{color: "white"}}> <FiPhoneCall size={28}/> Nazovite</a></span>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 mt--10">
                            <div style={{textAlign: "center", background: "#FFFFFF", padding: "5px", border: "1px solid gray", borderRadius: "8px"}}>
                                    <span className="category"><a href="mailto:krcina28@yahoo.com" style={{color: "black"}}> <HiOutlineMailOpen size={28}/> Email</a></span>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Projectcard;
