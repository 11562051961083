import { FiActivity, FiMonitor, FiNavigation } from "react-icons/fi";

import { GiAnchor } from "react-icons/gi"
import React from 'react';

const Service = () => {
    return (
        <div className="rn-service-area rn-section-gapBottom" style={{paddingBottom: "50px !important", marginTop: "50px !important"}}>
            {/* Start Service Area  */}
            <div className="rn-service-area">
                <div className="container">
                    <div className="row">

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <GiAnchor />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">"FIVE STAR" RECENZIJE</h4>
                                        <p>Korisnici nas redovno ocjenjuju sa 5 zvjezdica što jamči da ćete dobiti najbolju moguću kvalitetu, a zadovoljstvo je zajamčeno ili vraćamo novac</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <GiAnchor />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">POPUSTI ZA GRUPE</h4>
                                        <p>Ako planirate iznajmiti više od jednog broda, dobićete popust za svaki sljedeći brod.Provjerite ovu izvanrednu priliku.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <GiAnchor />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">IDEMO! VRIJEME JE ZA AVANTURU</h4>
                                        <p>Rezervirajte jedan od naših brodova i uživajte u neograničenim miljama. Uz naše posebne ponude možete lako pronaći jeftini brod za iznajmljivanje u zadnjoj minuti.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                    </div>
                </div>
            </div>
            {/* End Service Area  */}

        </div>
    )
}

export default Service;